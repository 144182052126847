<template>
  <div
    class="g-section myzone-section"
    style="flex-direction: column;align-items: center;"
  >
    <div class="banner">
      <div class="banner-cont">
        <div class="banner-cont-left">
          <img :src="enterpriseInfo.LogoFullPath" />
          <div class="banner-info">
            <div class="banner-name">{{ enterpriseInfo.EnterpriseName }}</div>
            <div class="banner-nature">
              {{ enterpriseInfo.NatureName }} |
              {{ enterpriseInfo.IndustryName }} | {{ enterpriseInfo.ScaleName }}
            </div>
          </div>
        </div>
        <div class="banner-btn" @click="$router.push('enterprise/update')">
          <i class="el-icon-edit"></i>
          管理公司信息
        </div>
      </div>
    </div>
    <div class="g-wrap myzone">
      <div class="info-box">
        <div class="info" @click="$router.push('/enterprise/job')">
          <img src="@/assets/images/enterprise/job.png" />
          <div class="info-cont">
            <div class="info-title">已发布岗位</div>
            <div class="info-num">
              <span>{{ RecruitJobCount }}</span
              >个
            </div>
          </div>
        </div>
        <!-- <div class="info" @click="$router.push('/enterprise/job/seeker')">
          <img src="@/assets/images/enterprise/resume.png" />
          <div class="info-cont">
            <div class="info-title">收到的简历</div>
            <div class="info-num">
              <span>{{ ResumeDeliveryCount }}</span
              >份
            </div>
          </div>
        </div> -->
        <!-- <div class="info" @click="$router.push('/enterprise/chat')">
          <img src="@/assets/images/enterprise/msg.png" />
          <div class="info-cont">
            <div class="info-title">我的消息</div>
            <div class="info-num">
              <span>{{ UnReadMessageCount }}</span
              >条未读
            </div>
          </div>
        </div> -->
        <div class="info" @click="$router.push('/enterprise/course')">
          <img src="@/assets/images/enterprise/course.png" />
          <div class="info-cont">
            <div class="info-title">课程资源</div>
            <div class="info-num">
              <span>{{ CourseSeriesCount }}</span
              >门
            </div>
          </div>
        </div>
      </div>
      <div class="trend-box">
        <div class="trend">
          <div class="trend-top">
            <div class="trend-title">近期趋势</div>
            <el-tabs v-model="activeName" @tab-click="tabClick">
              <el-tab-pane label="收到的简历" name="first"></el-tab-pane>
              <el-tab-pane label="在线沟通人数" name="second"></el-tab-pane>
              <!-- <el-tab-pane label="我看过的人才" name="third"></el-tab-pane> -->
            </el-tabs>
          </div>
          <div class="trend-cont" id="trend-cont" ref="myCharts"></div>
        </div>
        <div class="operate">
          <div class="operate-title">常用操作</div>
          <router-link to="/enterprise/job/create" class="operate-text">
            <img src="@/assets/images/enterprise/operate1.png" />
            发布招聘岗位
          </router-link>
          <router-link to="/enterprise/course/create" class="operate-text">
            <img src="@/assets/images/enterprise/operate2.png" />
            发布课程资源
          </router-link>
          <!-- <router-link to="/enterprise/recruitnews/create" class="operate-text">
            <img src="@/assets/images/enterprise/operate3.png" />
            发布招生动态
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import { getStatistics } from "@/api/enterprise/enterpriseInfo";
import { StaffTypeEnum } from "@/utils/constant";
export default {
  data() {
    return {
      RecruitJobCount: 0,
      ResumeDeliveryCount: 0,
      UnReadMessageCount: 0,
      CourseSeriesCount: 0,
      Trend: [],
      enterpriseInfo: {},
      activeName: "first",
      charts: "",
      chartsData1: ["3", "2", "4", "3", "5", "2", "1"],
      chartsData2: ["3", "5", "4", "0", "5", "2", "1"],
      chartsData3: ["3", "1", "4", "2", "5", "2", "1"],
      opinionData: [],
      data: ["06.18", "06.19", "06.20", "06.21", "06.22", "06.23", "06.24"]
    };
  },
  computed: {
    ...mapGetters(["employee"])
  },
  mounted() {
    // 获取企业信息
    var company = this.employee.find(
      p => p.StaffType == StaffTypeEnum.Enterprise
    );
    if (company) {
      this.enterpriseInfo = company.Employer;
    }

    getStatistics(this.enterpriseInfo.Id).then(
      ({
        Result: {
          RecruitJobCount,
          ResumeDeliveryCount,
          UnReadMessageCount,
          CourseSeriesCount,
          Trend = []
        } = {}
      }) => {
        this.RecruitJobCount = RecruitJobCount;
        this.ResumeDeliveryCount = ResumeDeliveryCount;
        this.UnReadMessageCount = UnReadMessageCount;
        this.CourseSeriesCount = CourseSeriesCount;
        this.Trend = Trend;
        this.opinionData = Trend[0].Result;
        this.drawLine("trend-cont");
      },
      res => console.log(res)
    );
  },
  methods: {
    tabClick(tab) {
      if (tab.index == 0) {
        this.opinionData = this.Trend[0].Result;
      }
      if (tab.index == 1) {
        this.opinionData = this.Trend[1].Result;
      }
      if (tab.index == 2) {
        // this.opinionData = this.Trend[2].Result;
      }
      this.$nextTick(() => this.drawLine("trend-cont"));
    },
    // echarts绘图
    drawLine(id) {
      if (!this.charts) {
        this.charts = echarts.init(document.getElementById(id));
      }
      this.charts.setOption({
        grid: {
          left: "24px",
          right: "24px",
          bottom: "24px",
          containLabel: true
        },
        color: ["#F2AF50"],
        tooltip: {
          backgroundColor: "#FFF",
          borderColor: "#FFF"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.opinionData.map(({ Text }) => Text.substring(5))
        },
        yAxis: {
          type: "value",
          minInterval: 1
        },
        series: [
          {
            type: "line",
            data: this.opinionData.map(({ Value }) => Value)
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.myzone-section {
  background-color: #f5f5f5;
}
.myzone {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: 24px;
}
.myzone-module {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 200px;
  margin-top: 20px;
}
.module-box {
  width: 24%;
  border: 1px solid #409eff;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  padding: 24px 0 0 24px;
}
.operates {
  .operate {
    height: 112px;
    margin: 24px;
    margin-right: 0;
    padding-right: 24px;
    border-right: 1px solid #eeeeee;
    flex: 1;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }
    a {
      text-decoration: none;
    }
    .operate-text {
      text-align: center;
      color: #666;
      font-size: 18px;
    }
    .card-icon {
      color: #598DF3;
      width: 100%;
      text-align: center;
      margin: 16px auto;
      font-size: 32px;
    }
    &:hover {
      .card-icon {
        font-size: 34px;
      }
    }
  }
}
.banner {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/images/banner/enterprise.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .banner-cont {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    .banner-cont-left {
      display: flex;
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 8px;
      }
      .banner-info {
        padding: 11px 0;
        box-sizing: border-box;
        .banner-name {
          margin-bottom: 5px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 33px;
        }
        .banner-nature {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .banner-btn {
      width: 148px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      border: 1px solid #ffffff;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: #598DF3;
        border: 1px solid #598DF3;
      }
    }
  }
}
.info-box {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  .info {
    width: 49.5%;
    height: 130px;
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 32px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.12);
    }
    img {
      width: 56px;
      height: 56px;
      margin-right: 16px;
    }
    .info-cont {
      height: 100%;
      font-size: 18px;
      color: #666666;
      line-height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info-num {
        font-size: 16px;
        span {
          font-size: 36px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          padding-right: 8px;
        }
      }
    }
  }
}
.trend-box {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  .trend {
    width: 821px;
    height: 320px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .trend-top {
      height: 60px;
      padding: 24px 24px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      .trend-title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
        line-height: 25px;
      }
      .el-tabs {
        .el-tabs__item {
          height: 30px;
          line-height: 30px;
          background-color: red;
        }
        .el-tabs__nav-wrap::after {
          height: 0;
          background-color: unset;
        }
      }
    }
    .trend-cont {
      flex-grow: 1;
    }
  }
  .operate {
    width: 263px;
    height: 320px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    .operate-title {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #666666;
      line-height: 25px;
      margin-bottom: 23px;
    }
    .operate-text {
      display: block;
      width: 215px;
      height: 64px;
      line-height: 64px;
      display: flex;
      align-items: center;
      background: #f5f5f5;
      border-radius: 4px;
      font-size: 16px;
      color: #333333;
      margin-bottom: 16px;
      text-decoration: none;
      padding-left: 24px;
      box-sizing: border-box;
      &:hover {
        background-color: #dff6ee;
        color: #598DF3;
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }
}
.course-box {
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  .course-top {
    height: 60px;
    padding: 24px 24px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    .trend-title {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #666666;
      line-height: 25px;
    }
    .course-more {
      font-size: 14px;
      color: #598DF3;
      line-height: 20px;
      padding-top: 4px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .course-cont {
    box-sizing: border-box;
    padding: 16px 24px 24px;
  }
}
.right-buttons {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
<style lang="scss">
.card-box > .card:last-child {
  border-right: none;
}
.trend-top {
  .el-tabs {
    .el-tabs__item {
      height: 36px;
      line-height: 36px;
    }
    .el-tabs__nav-wrap::after {
      height: 0;
      background-color: unset;
    }
  }
}
</style>
